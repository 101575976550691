import React from 'react'

export default function HeadingText() {
  return (
    <>    
        <div>
            <h2>Live Music Americana - Rock, Folk,<br />Outlaw Country, & Celtic</h2>
        </div>
    </>
  )
}
