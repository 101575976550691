import React from 'react'
import '../css/AlbumRelease.css';

export default function AlbumRelease() {
  return (
    <div className="release">
        <a href="https://linktr.ee/masonskylerwhifield" target="_blank">DOWNLOAD MY NEW ALBUM "MASON" HERE !</a>
    </div>
  )
}
