import React from 'react'
import Navbar2 from "../components/NavbarToHome";
import AllShows from '../components/AllShows';


export default function Shows() {
  return (
    <div>
        <Navbar2 />
        <AllShows />
        <br /><br /><br />
    </div>
  )
}
